import React, { useEffect } from 'react';
import get from 'lodash/get';
import { NoSsr } from '@material-ui/core';
import {
  createDynamicLabels,
} from '../../../../utils/analytics';
import Picture from '../../atoms/Picture';
import aspectRatios from '../../../utils/AspectRatios';
import CustomVideo from '../../atoms/Video';
import { plyrVideo } from '../../../utils/video';
import YoukuPlayer from '../../molecules/YoukuPlayer';
import { isInsideChina } from '../../../utils/cookie';
import { analyticsData } from '../../../../constants/Analytics/analytics-data';
import Title from '../../atoms/Title';
import TextRotateAnimation from '../../atoms/TextRotateAnimation';
import useStyles from './style';
import isEmpty from 'lodash/isEmpty';

const ImageVideoComponent = ({
  mediaField,
  aspectRatio = aspectRatios['3x2'],
  sitecoreContext,
  pageName,
  componentName,
  heading,
  title,
  shouldAutoplay = true,
  articleKey,
  variation = ''
}) => {
  const classes = useStyles();
  let videoUrl = get(mediaField, 'video.value.href', '') || '';
  let playerDOMRef = null;
  const setPlayerDOMRef = ref => {
    playerDOMRef = ref;
  };
  const anayltyicsTitle = createDynamicLabels([
    pageName,
    componentName,
    `${get(sitecoreContext, 'route.name', '')}-${heading && heading.value}`,
  ]);

  useEffect(() => {
    if (!isInsideChina(sitecoreContext) && videoUrl && videoUrl.length !== 0) {
      let plyrDOM = document.querySelector('.plyr-video');
      const plyrDOMInterval = setInterval(() => {
        plyrDOM = document.querySelector('.plyr-video');
        if (plyrDOM) {
          clearInterval(plyrDOMInterval);
          plyrVideo({
            playerDOMRef: playerDOMRef,
            analyticsData: analyticsData.pageBodyInteraction.videoPlayClick,
            titleText: anayltyicsTitle,
            shouldShowPoster: false,
            iosNative: false,
            ...(!isEmpty(variation) && { from: variation }),
          });
        }
      }, 100);
    }
  }, [mediaField, articleKey]);

  // Desktop fallback image
  if (!get(mediaField, 'image.value.src', '') && videoUrl.length === 0) {
    mediaField.image = {
      value: {
        src: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAACCAQAAAA3fa6RAAAADklEQVR42mNkAANGCAUAACMAA2w/AMgAAAAASUVORK5CYII=',
        '3x2': {
          large: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAACCAQAAAA3fa6RAAAADklEQVR42mNkAANGCAUAACMAA2w/AMgAAAAASUVORK5CYII=',
          small: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAACCAQAAAA3fa6RAAAADklEQVR42mNkAANGCAUAACMAA2w/AMgAAAAASUVORK5CYII=',
        },
      },
    }
  }

  //mobile fallbackImage
  if (!get(mediaField, 'mobileImage.value.src', '') && videoUrl.length === 0) {
    mediaField.mobileImage = {
      ...mediaField.image,
    };
  }

  //This will check user is inside china or chinese language is selected.
  if (isInsideChina(sitecoreContext)) {
    //check if variant is video and youku player url is there.
    videoUrl = get(mediaField, 'youkuVideo.value.href', '') || '';
    if (!videoUrl.includes('youku')) {
      videoUrl = '';
    }
  }
  return (
    <div className={classes.root}>
      {videoUrl.length === 0 ? (
        <Picture
          media={{
            image: mediaField.image,
            mobileImage: mediaField.mobileImage,
            aspectRatio,
          }}
          className={'img-vid-comp_img'}
          alternateAltText={heading && heading.value}
        />
      ) : isInsideChina(sitecoreContext) ? (
        <YoukuPlayer
          {...{
            media: {
              image: mediaField.image,
              aspectRatio,
            },
            playerHeight: '100%',
            sitecoreContext,
            youkuVideo: mediaField.youkuVideo,
            fallbackImage: mediaField.fallbackImage,
            anayltyicsTitle,
            componentName,
            pageName,
            alternateAltText: heading && heading.value,
            customClassPicture: 'img-vid-comp_img',
            uniqueId: articleKey,
          }}
        />
      ) : (
        <NoSsr>
          <CustomVideo
            video={mediaField.video}
            posterImage={mediaField.image}
            mobileImage={mediaField.mobileImage}
            setPlayerDOMRef={setPlayerDOMRef}
            aspectRatio={aspectRatio}
            playIcon={
              mediaField.playIcon &&
              mediaField.playIcon.value &&
              mediaField.playIcon.value.src
            }
            closeButtonSize="small"
            alternateAltText={heading && heading.value}
            shouldAutoplay={shouldAutoplay}
          />
        </NoSsr>
      )}
      {
        title && title.value &&
        <div className="caption-text">
          <TextRotateAnimation
            condition={true}
            animateSettings={{
              willAnimate: true,
              selfTrigger: true,
              duration: '250ms',
            }}
          >
            <Title
              text={title}
              variant="body1Bold22"
              className='text-line'
            />
          </TextRotateAnimation>
        </div>
      }
    </div>

  );
};

export default ImageVideoComponent;